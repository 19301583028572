<template>
 <div v-loading.fullscreen.lock="loading" :element-loading-text="$t('connecting')" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
     <div class="w-full mt-10" style="padding: 0px 20px;">
     <div class="header-container">
                <div class="header-item " :class="activeName==1 ? 'header-item-active': ''" @click="activeName=1">
                    <div class="header-radio-label"></div>
                    {{$t('instruction')}}
                </div> 
                <div class="header-item " :class="activeName==2 ? 'header-item-active': ''" @click="activeName=2">
                    <div class="header-radio-label"></div>
                    {{$t('contact')}}
                </div> 
                <div class="header-item " :class="activeName==3 ? 'header-item-active': ''" @click="activeName=3">
                    <div class="header-radio-label"></div>
                    {{$t('nor_doc')}}
                </div> 
            </div>
        </div>
     <div class="help-body " style="height:80vh;" v-if="activeName==1">
         <iframe :src="activePdf==1 ? `./Uz.pdf` : `./Ru.pdf`" style="width:100%; height:100%;" class="scroll" frameborder="0"></iframe>
         <div class="left-controls">
             <div class="video-play-button">
                 <button @click="Play()">
                     <img width="18px" style="margin-left:1px;" src="@/assets/img/play.svg" alt="">
                 </button>
             </div>
             <div class="pdf-chager">
                 <button style="padding:10px 15px 10px 20px" :class="activePdf==1 ? `active-pdf-lang`:` `" @click="activePdf=1">Ўз</button>
                 <button style="padding:10px 20px 10px 15px" :class="activePdf==2 ? `active-pdf-lang`:` `" @click="activePdf=2">Ру</button>
             </div>
         </div>

     </div>
     <div class="contac-body" v-if="activeName==2">
         <el-row :gutter="24">
             <el-col :md="8">
                 <div class="w-full">
                     <p class="contact-title">{{$t('data_for_contact')}}:</p>
                     <div class="contack-items">
                         <div class="contact-item">
                             <p> <i class="el-icon-phone mr-2"> </i> +998 (71) 208-81-51 (4040)</p>
                         </div>
                         <div class="contact-item">
                             <p> <i class="el-icon-phone mr-2"> </i> +998 (99) 800-58-52 (телеграм)</p>
                         </div>
                         <div class="contact-item">
                             <p> <i class="el-icon-message mr-2"> </i> info@binopasport.uz</p>
                         </div>
                         <div class="contact-item">
                             <p> <i class="el-icon-location mr-2"> </i> 100047, Toshkent sh. Yaxyo G'ulomov, 70</p>
                         </div>

                         <div class="telegram-link">
                             <p>{{$t('join_group_answer')}}.</p>

                             <a href="https://t.me/joinchat/QnczmsV4ANA1OTgy">
                                 <button class="primary-btn"> <i class="mr-2 el-icon-s-promotion"></i> {{$t('join_group')}} </button>
                             </a>
                         </div>
                     </div>

                 </div>
             </el-col>
             <el-col :md="16">
                 <div class="w-full map-item h-full">
                     <yandex-map :coords="coords" style="height:600px; width: 100%" :zoom="14" :controls="['zoomControl']">
                         <ymap-marker :coords="coords" marker-id="123" hint-content="some hint" />
                     </yandex-map>
                 </div>
             </el-col>

         </el-row>
     </div>
     <div class="help-body " style="height:80vh;" v-if="activeName==3">
         <iframe src="https://lex.uz/" style="width:100%; height:100%;" class="scroll" frameborder="0"></iframe>
     </div>
     <div class="video-cover" v-show="video">
         <div class="fixed-cover"></div>
         <div class="video-item">
             <div class="close-video">
                 <button @click="Pause()"> <i class="el-icon-close"></i></button>
             </div>
             <video class="video-player" controls ref="video" src="https://pasportbino.uz/uploads/video-guide/video.mp4"></video>
         </div>
     </div>
 </div>
</template>

<script>
export default {
    data() {
        return {
            activeName: 1,
            loading: false,
            activeNames: [],
            activePdf: 1,
            video: false,
            b: '',
            coords: [41.306626, 69.286564],

        };
    },

    methods: {

        Pause() {
            this.$refs.video.pause()
            this.video = false
        },
        Play() {
            this.$refs.video.play()
            this.video = true
        },

    },

};
</script>

<style lang="scss">
.df {
    display: flex;
}

.jcsb {
    justify-content: space-between;
}

.p0 {
    padding: 0 !important;
}

.p-help {
    padding: 25px 20px 20px 20px !important;
}

 

.help-body {
    padding: 25px 20px 20px 20px;
    position: relative;
}

.bg-active {
    background: #004787;
    color: #fff;
    .header-radio-label{
            
             background: linear-gradient(180deg, #C5C5C5 0%, #FFFFFF 100%);
            &::after{
                display: block;
            }
        }
}

.faz-answer {
    font-size: 16px;
}

.faq-question {
    font-size: 16px;
    font-weight: 500;
}

.is-active .faq-question {
    font-weight: 600 !important;
}

.w50 {
    width: 50%;
}

.left-controls {
    position: absolute;
    bottom: 20px;
    right: 30px;
    z-index: 10;
}

.pdf-chager {
    border-radius: 5px;
    overflow: hidden;
}

.pdf-chager button {
    background: #ffff;
    color: #000;
    font-weight: 600;
}

.active-pdf-lang {
    background: #004787 !important;
    color: #fff !important;
}

.video-play-button {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
}

.video-play-button button {
    width: 60px;
    height: 60px;
    background: #004787 !important;
    color: #fff !important;
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.video-cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10001;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.video-item {
    position: relative;
    width: 80%;
    background: #fff;
    height: 80%;
    position: relative;
}

.video-player {
    width: 100%;
    height: 100%;
    background: #000;
}

.close-video {
    z-index: 100;
    position: absolute;
    top: -5px;
    right: -5px;
}

.close-video button {
    width: 30px;
    height: 30px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.close-video button i {
    font-weight: 600;
}

.play-pause-layer {
    display: none !important;
}

.contac-body {
    padding: 30px 30px;
    padding-top: 50px;
}

.contact-title {
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 600;
}

.contack-items {
    width: 100%;
}

.contact-item {
    padding: 25px 0px 5px 0px;
    border-bottom: 1px solid rgb(207, 207, 207);
    font-size: 15px;
    font-weight: 500;
}

.map-item {
    border-radius: 10px;
    overflow: hidden;
}

.telegram-link {
    padding: 20px 0px;
}

.telegram-link p {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.telegram-link button {
    padding: 15px 20px;
    border-radius: 10px;
}
</style>
